import { DocumentType, PathnameType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import ViewModal from '~/components/View/Modal';
import { ListItemProps } from '~/containers/Admin/props';
import IconClone from '~/icons/Clone';
import IconDelete from '~/icons/Delete';
import { colors } from '~/styles/theme';

import ListItem from './index';

const ListItemPathname: FC<ListItemProps<PathnameType>> = ({
  docId,
  index,
  model,
}) => {
  const [openDelete, setOpenDelete] = useState<boolean>();
  const [doc, setDoc] = useState<DocumentType<PathnameType>>();
  const [doublon, setDoublon] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);

        if (document) {
          model
            .find({
              wheres: {
                value: document.value,
              },
            })
            .then(docs => {
              if (isMounted) {
                setDoublon(docs.length > 1);
              }

              return isMounted;
            })
            .catch(error => toast.error(error.message));
        }
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  const handleDeleteDoc = async () => {
    if (doc && doc.id) {
      await toast.promise(model.delete(doc.id), {
        error: 'Problème lors de la suppression',
        pending: 'Suppression en cours',
        success: 'Suppression réussie',
      });
    } else {
      await toast(`Problème lors de la suppression du chemin`);
    }
  };

  const handleDeleteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.preventDefault();

    setOpenDelete(true);
  };

  const handleDeleteConfirmOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    await handleDeleteDoc();
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      actions={[
        {
          icon: IconDelete,
          iconColor: 'danger',

          label: 'Supprimer le chemin',
          onClick: handleDeleteOnClick,
        },
      ]}
      doc={doc}
      index={index}
      label={doc.value}
    >
      {openDelete && (
        <ViewModal
          closeOnClick={handleCloseDelete}
          maxWidth="500px"
          title="Suppression"
        >
          <p>Est-ce que vous souhaitez vraiment supprimer ce chemin ?</p>
          <div className="mt-3 flex items-center justify-center space-x-3">
            <Button
              color="neutral"
              onClick={handleCloseDelete}
              variant="outline"
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={handleDeleteConfirmOnClick}
              variant="solid"
            >
              Oui
            </Button>
          </div>
        </ViewModal>
      )}

      <div className="ml-3 flex flex flex-1 justify-between">
        <div className="flex space-x-3">
          {doublon && <IconClone color={colors.danger['500']} />}
          <strong>{doc.value}</strong>
        </div>

        <p className="flex space-x-3">
          <span>{doc.collectionName}</span>
          <span>{doc.docId}</span>
        </p>
      </div>
    </ListItem>
  );
};

export default ListItemPathname;
